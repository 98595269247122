import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { getNotice, getNoticeDetail } from '../../../api/customer'
import { Modal } from 'react-bootstrap'

const NoticePage = ({
  intl,
  showModal,
  pagination,
  detail,
  handleChangePage,
  handleOpenModal,
  handleCloseModal
}) => (
  <>
    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>
            {intl.formatMessage({
              id: 'Registered announcement does not exist.'
            })}
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Title' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of creation' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr
                        className="c-cp"
                        key={idx}
                        onClick={() => handleOpenModal(item.id)}
                      >
                        <th>{item.id}</th>
                        <th>{item.title}</th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-10">{detail.title}</h1>
      <div
        style={{ minHeight: '300px' }}
        dangerouslySetInnerHTML={{ __html: detail.content }}
      ></div>
      <div className="c-tar mt-5">
        {intl.formatMessage({
          id: 'Date Created'
        })}{' '}
        : {detail.createdAt}
      </div>
    </Modal>
  </>
)

const NoticeWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state
  const [showModal, setShowModal] = useState(false)
  const [detail, setDetail] = useState({
    title: '',
    content: '',
    createdAt: ''
  })

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // handler
  const handleOpenModal = async (id) => {
    const params = {
      id
    }
    const { data: response } = await getNoticeDetail(params)
    const data = response.data
    setDetail({
      title: data.title,
      content: data.content,
      createdAt: data.createdAt
    })
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber
      }
      const { data: response } = await getNotice(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          activePage: pageNumber,
          totalItemsCount: response.data.noticeTotalCount,
          data: response.data.noticeList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // init data
  const initData = async () => {
    // set table
    try {
      const params = {
        page: pagination.activePage
      }
      const { data: response } = await getNotice(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.noticeTotalCount,
          data: response.data.noticeList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Notice' })}
      </PageTitle>

      <NoticePage
        intl={intl}
        showModal={showModal}
        pagination={pagination}
        detail={detail}
        handleChangePage={handleChangePage}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export { NoticeWrapper }
