import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DATE from '../../constants/date'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import {
  walletListPagination,
  updateTempAmount as updateTempAmountAPI,
  updateUser as updateUserAPI,
  tokenLockUp,
  estimateFee,
  recoveryTokenTransfer,
  getEthBalance
} from '../../../api/admin'
import { Modal } from 'react-bootstrap'
import QRCode from 'react-qr-code'

const WalletManagementPage = ({
  intl,
  isDisabled,
  selectRow,
  search,
  pagination,
  lockup,
  recoveryToken,
  selectBoxAdmin,
  selectBoxDeveloper,
  selectBoxEmail,
  selectBoxSMS,
  selectBoxOTP,
  showModal,
  showUserModal,
  showTempAmountModal,
  updateUserInfo,
  updateTempAmount,
  handleChangePage,
  handleClickSearch,
  handleChangeSearch,
  handleOpenModal,
  handleCloseModal,
  handleOpenUserModal,
  handleCloseUserModal,
  handleOpenTempAmountModal,
  handleCloseTempAmountModal,
  handleChangeUserInfo,
  handleChangeTempAmount,
  handleClickUpdateUser,
  handleClickupdateTempAmount,
  showLockUpModal,
  handleOpenLockUpModal,
  handleCloseLockUpModal,
  handleChangeLockUp,
  handleClickTokenLockUp,
  handleChangeRecoveryToken,
  showRecoveryTokenModal,
  handleOpenRecoveryTokenModal,
  handleCloseRecoveryTokenModal,
  handleClickEstimateFee,
  handleClickRecoveryToekn,
  handleClickGetEthBalance
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="account">
                {intl.formatMessage({ id: 'Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="account"
                value={search.account}
                onChange={(e) => handleChangeSearch('account', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="username">
                {intl.formatMessage({ id: 'Username' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="username"
                value={search.username}
                onChange={(e) => handleChangeSearch('username', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="createdAt">
                {intl.formatMessage({ id: 'Date and time of creation' })}
              </label>
              <DatePicker
                className="form-control mt-3"
                dateFormat="yyyy-MM-dd"
                selected={search.createdAt}
                onChange={(date: Date) => handleChangeSearch('createdAt', date)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="isAdmin">
                {intl.formatMessage({ id: 'Administrator authority' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('isAdmin', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxAdmin.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="email">
                {intl.formatMessage({ id: 'Email Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('is_email_verify', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxEmail.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sms">
                {intl.formatMessage({ id: 'SMS Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('is_sms_verify', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxSMS.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sms">
                {intl.formatMessage({ id: 'OTP Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('isActiveOtp', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxSMS.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button className="btn btn-light-primary" onClick={handleClickSearch}>
            <i className="bi bi-search fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Search' })}
          </button>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>
            <div className="c-tac">
              {intl.formatMessage({
                id: 'Data does not exist.'
              })}
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-hover table-row-bordered  border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Account' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Username' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Wallet Address' })}
                    </th>
                    <th scope="col">ETH</th>
                    <th scope="col">CSTN</th>
                    <th scope="col">락업 물량</th>
                    <th scope="col">임시 입고잔액</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of creation' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of update' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="c-cp"
                        onClick={() => handleOpenModal(item)}
                      >
                        <th>{item.id}</th>
                        <th>{item.account}</th>
                        <th>{item.username}</th>
                        <th>{item.walletAddress}</th>
                        <th>{item.ethBalance}</th>
                        <th>{item.tokenBalance}</th>
                        <th>{item.lockupBalance}</th>
                        <th>
                          {item.tempAmount !== null ? (
                            <span className="badge badge-success">
                              {item.tempAmount}
                            </span>
                          ) : (
                            <span className="badge badge-danger">
                              설정되지않음
                            </span>
                          )}
                        </th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                        <th>
                          {item.updatedAt
                            ? moment(item.updatedAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change user information' })}
      </h1>
      <div className="mt-3 c-tac">
        <QRCode size={100} value={selectRow?.walletAddress} />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="account">{intl.formatMessage({ id: 'Account' })}</label>
        <input
          type="test"
          className="form-control mt-3"
          id="account"
          value={selectRow?.account}
          disabled
        />
        <label className="mt-3" htmlFor="walletAddress">
          {intl.formatMessage({ id: 'Wallet Address' })}
        </label>
        <input
          type="test"
          className="form-control mt-3"
          id="walletAddress"
          value={selectRow?.walletAddress}
          disabled
        />
        <label className="mt-3" htmlFor="tempAmount">
          임시 입고잔액
        </label>
        <input
          type="test"
          className="form-control mt-3"
          id="tempAmount"
          value={selectRow?.tempAmount ? selectRow.tempAmount : '설정되지않음'}
          disabled
        />
        <label className="mt-3" htmlFor="tempAmount">
          락업 물량
        </label>
        <input
          type="test"
          className="form-control mt-3"
          id="lockupBalance"
          value={selectRow?.lockupBalance}
          disabled
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-primary c-w100p mt-5"
            onClick={handleOpenTempAmountModal}
          >
            <i className="bi bi-arrow-left-circle mr-1"></i>
            임시입고변경
          </button>
          <button
            className="btn btn-primary c-w100p mt-5"
            onClick={handleOpenLockUpModal}
          >
            <i className="bi bi-arrow-left-circle mr-1"></i>
            락업 설정
          </button>
          <button
            className="btn btn-primary c-w100p mt-5"
            onClick={handleOpenRecoveryTokenModal}
          >
            <i className="bi bi-arrow-left-circle mr-1"></i>
            토큰 회수
          </button>
        </div>
      </div>
    </Modal>

    <Modal show={showUserModal} onHide={handleCloseUserModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseUserModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change user information' })}
      </h1>
      <div className="form-group mt-5">
        <label htmlFor="username">
          {intl.formatMessage({ id: 'Username' })}
        </label>
        <input
          type="text"
          className="form-control mt-3"
          id="username"
          value={updateUserInfo?.username}
          onChange={(e) => handleChangeUserInfo('username', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="phone">{intl.formatMessage({ id: 'Phone' })}</label>
        <input
          type="text"
          className="form-control mt-3"
          id="phone"
          value={updateUserInfo?.phone}
          onChange={(e) => handleChangeUserInfo('phone', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'Administrator authority' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('admin', e)}
        >
          {selectBoxAdmin.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isAdmin === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'Email Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('email', e)}
        >
          {selectBoxEmail.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isEmail === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'SMS Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('sms', e)}
        >
          {selectBoxSMS.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isSMS === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'OTP Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('otp', e)}
        >
          {selectBoxOTP.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isOTP === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={handleClickUpdateUser}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i>{' '}
            {intl.formatMessage({ id: 'BUTTON.Update' })}
          </button>
        </div>
      </div>
    </Modal>

    {/* START 임시입고 모달 */}
    <Modal show={showTempAmountModal} onHide={handleCloseTempAmountModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseTempAmountModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">임시입고 변경</h1>
      <div className="form-group mt-5">
        <label htmlFor="tempAmount">임시입고 금액</label>
        <input
          type="number"
          className="form-control mt-3"
          id="tempAmount"
          value={updateTempAmount?.newPassword}
          onChange={(e) => handleChangeTempAmount('newPassword', e)}
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={() => handleClickupdateTempAmount(false)}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i>{' '}
            {intl.formatMessage({ id: 'BUTTON.Update' })}
          </button>
        </div>
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-danger c-w100p"
            onClick={() => handleClickupdateTempAmount(true)}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i> 입시입고 비활성화
          </button>
        </div>
      </div>
    </Modal>
    {/* END 임시입고 모달 */}

    {/* START 락업 모달 */}
    <Modal show={showLockUpModal} onHide={handleCloseLockUpModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseLockUpModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">락업 설정</h1>
      <div className="form-group mt-5">
        <label htmlFor="tempAmount">입금 날짜</label>
        <DatePicker
          className="form-control mt-3"
          dateFormat="yyyy-MM-dd"
          selected={lockup.depositDate}
          onChange={(date: Date) => handleChangeLockUp('depositDate', date)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="amount">락업 물량</label>
        <input
          type="number"
          className="form-control mt-3"
          id="amount"
          name="amount"
          value={lockup?.amount}
          onChange={(e) => handleChangeLockUp('amount', e)}
        />
      </div>

      <div className="form-group mt-5">
        <label htmlFor="monthlyLockUpDate">락업 기간 (월 단위)</label>
        <input
          type="number"
          className="form-control mt-3"
          id="monthlyLockUpDate"
          name="monthlyLockUpDate"
          value={lockup?.monthlyLockUpDate}
          disabled={true}
          onChange={(e) => handleChangeLockUp('monthlyLockUpDate', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="monthlyPercent">락업 해제 물량 (퍼센트)</label>
        <input
          type="number"
          className="form-control mt-3"
          id="monthlyPercent"
          name="monthlyPercent"
          value={lockup?.monthlyPercent}
          disabled={true}
          onChange={(e) => handleChangeLockUp('monthlyPercent', e)}
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={handleClickTokenLockUp}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i>{' '}
            {intl.formatMessage({ id: 'BUTTON.Update' })}
          </button>
        </div>
      </div>
    </Modal>
    {/* END 락업 모달 */}

    {/* START CSTN 회수 모달 */}
    <Modal show={showRecoveryTokenModal} onHide={handleCloseRecoveryTokenModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseRecoveryTokenModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">토큰 회수</h1>
      <label className="text-center" htmlFor="amount">
        지갑주소 QR 코드
      </label>
      <div className="mt-3 c-tac">
        <QRCode size={100} value={selectRow?.walletAddress} />
      </div>
      <div className="form-group mt-5 ">
        <label htmlFor="amount">이더리움 잔액</label>
        <button
          className="btn btn-icon btn-light-facebook me-5 ml-3"
          onClick={() => handleClickGetEthBalance(selectRow)}
        >
          <i className="fa-solid fa-arrows-rotate"></i>
        </button>

        <input
          type="number"
          className="form-control mt-3"
          value={selectRow?.ethBalance}
          disabled={true}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="amount">CSTN 잔액</label>
        <input
          type="number"
          className="form-control mt-3"
          value={selectRow?.tokenBalance}
          disabled={true}
        />
      </div>

      <div className="form-group mt-5">
        <label htmlFor="monthlyLockUpDate">회수할 물량</label>
        <input
          type="number"
          className="form-control mt-3"
          id="amount"
          name="amount"
          value={recoveryToken.amount}
          onChange={(e) => handleChangeRecoveryToken('amount', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="monthlyPercent">회수받을 지갑주소</label>
        <input
          type="text"
          className="form-control mt-3"
          id="toAddress"
          name="toAddress"
          value={recoveryToken?.toAddress}
          onChange={(e) => handleChangeRecoveryToken('toAddress', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="monthlyPercent">
          예상 수수료 (아래 수수료금액을 해당지갑에 넣어주세요.)
        </label>
        <input
          type="text"
          className="form-control mt-3"
          id="toAddress"
          name="toAddress"
          value={recoveryToken?.totalFee}
          disabled={true}
          onChange={(e) => handleChangeRecoveryToken('toAddress', e)}
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={handleClickEstimateFee}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i> 예상 수수료 확인
          </button>
        </div>
      </div>
      <div className="mt-5">
        <div>
          <button
            className="btn btn-danger c-w100p"
            onClick={handleClickRecoveryToekn}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i> 토큰 회수 하기
          </button>
        </div>
      </div>
    </Modal>
    {/* END CSTN 회수 모달 */}
  </>
)

const WalletManagementWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // state - search
  const [search, setSearch] = useState({
    id: '',
    account: '',
    username: '',
    isAdmin: '',
    isDeveloper: '',
    isEmailVerify: '',
    isSMSVerify: '',
    isActiveOtp: '',
    createdAt: ''
  })

  // state
  const [showModal, setShowModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [showTempAmountModal, setShowTempAmountModal] = useState(false)
  const [showLockUpModal, setShowLockUpModal] = useState(false)
  const [showRecoveryTokenModal, setShowRecoveryTokenModal] = useState(false)
  const [selectRow, setSelectRow] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)

  const [updateUserInfo, setUpdateUserInfo] = useState({
    userId: 0,
    username: '',
    phone: '',
    isAdmin: '',
    isEmail: '',
    isSMS: '',
    isOTP: ''
  })
  // temp amount state
  const [updateTempAmount, setUpdateTempAmount] = useState({
    userId: 0,
    tempAmount: 0
  })

  const [lockup, setLockUp] = useState({
    userId: 0,
    depositDate: '',
    monthlyLockUpDate: 5,
    monthlyPercent: 20,
    amount: 0
  })

  const [recoveryToken, setRecoveryToken] = useState({
    userId: 0,
    toAddress: '',
    amount: 0,
    gasPrice: '',
    gasLimit: '',
    totalFee: 0
  })

  // handler
  const handleOpenModal = (data) => {
    setUpdateUserInfo({
      userId: data.id,
      username: data.username,
      phone: data.phone,
      isAdmin: data.admin,
      isEmail: data.emailVerify,
      isSMS: data.smsVerify,
      isOTP: data.isActiveOTP
    })
    setUpdateTempAmount({
      userId: data.id,
      tempAmount: data.tempAmount
    })
    setLockUp({
      userId: data.id,
      depositDate: data.depositDate,
      monthlyLockUpDate: 5,
      monthlyPercent: 20,
      amount: data.amount
    })
    setRecoveryToken({
      userId: data.id,
      toAddress: data.toAddress,
      amount: data.amount,
      gasPrice: data.gasPrice,
      gasLimit: data.gasLimit,
      totalFee: data.totalFee
    })
    setSelectRow(data)
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // handler
  const handleOpenUserModal = () => {
    setShowUserModal(true)
  }

  // handler
  const handleCloseUserModal = () => {
    setShowUserModal(false)
  }

  // handler
  const handleOpenTempAmountModal = () => {
    setShowTempAmountModal(true)
  }

  // handler
  const handleCloseTempAmountModal = () => {
    setShowTempAmountModal(false)
  }
  // handler
  const handleOpenLockUpModal = () => {
    setShowLockUpModal(true)
  }

  // handler
  const handleCloseLockUpModal = () => {
    setShowLockUpModal(false)
  }

  // handler
  const handleOpenRecoveryTokenModal = () => {
    setShowRecoveryTokenModal(true)
  }

  // handler
  const handleCloseRecoveryTokenModal = () => {
    setShowRecoveryTokenModal(false)
  }

  // state - admin
  const [selectBoxAdmin, setSelectBoxAdmin] = useState([])

  // state - developer
  const [selectBoxDeveloper, setSelectBoxDeveloper] = useState([])

  // state - email
  const [selectBoxEmail, setSelectBoxEmail] = useState([])

  // state - sms
  const [selectBoxSMS, setSelectBoxSMS] = useState([])

  // state - otp
  const [selectBoxOTP, setSelectBoxOTP] = useState([])

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await walletListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - click search
  const handleClickSearch = async () => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // set table
    try {
      const params = {
        page: 1,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await walletListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - change search
  const handleChangeSearch = (type: string, data: any) => {
    if (type === 'id') {
      setSearch({ ...search, id: data.target.value })
    } else if (type === 'account') {
      setSearch({ ...search, account: data.target.value })
    } else if (type === 'username') {
      setSearch({ ...search, username: data.target.value })
    } else if (type === 'isAdmin') {
      setSearch({ ...search, isAdmin: data.target.value })
    } else if (type === 'isDeveloper') {
      setSearch({ ...search, isDeveloper: data.target.value })
    } else if (type === 'is_email_verify') {
      setSearch({ ...search, isEmailVerify: data.target.value })
    } else if (type === 'is_sms_verify') {
      setSearch({ ...search, isSMSVerify: data.target.value })
    } else if (type === 'isActiveOtp') {
      setSearch({ ...search, isActiveOtp: data.target.value })
    } else if (type === 'createdAt') {
      setSearch({ ...search, createdAt: data })
    }
  }

  // handler
  const handleChangeUserInfo = (type: string, data: any) => {
    if (type === 'username') {
      setUpdateUserInfo({ ...updateUserInfo, username: data.target.value })
    } else if (type === 'phone') {
      setUpdateUserInfo({ ...updateUserInfo, phone: data.target.value })
    } else if (type === 'admin') {
      setUpdateUserInfo({ ...updateUserInfo, isAdmin: data.target.value })
    } else if (type === 'email') {
      setUpdateUserInfo({ ...updateUserInfo, isEmail: data.target.value })
    } else if (type === 'sms') {
      setUpdateUserInfo({ ...updateUserInfo, isSMS: data.target.value })
    } else if (type === 'otp') {
      setUpdateUserInfo({ ...updateUserInfo, isOTP: data.target.value })
    }
  }

  // handler
  const handleChangeTempAmount = (type: string, data: any) => {
    if (data.target.value >= 0)
      setUpdateTempAmount({
        ...updateTempAmount,
        tempAmount: data.target.value
      })
  }

  // handler
  const handleChangeLockUp = (type: string, data: any) => {
    if (type === 'depositDate') {
      setLockUp({ ...lockup, depositDate: data })
    } else {
      setLockUp({ ...lockup, [data.target.name]: data.target.value })
    }
  }

  // handler
  const handleChangeRecoveryToken = (type: string, data: any) => {
    setRecoveryToken({
      ...recoveryToken,
      [data.target.name]: data.target.value
    })
  }

  // handler
  const handleClickUpdateUser = async () => {
    setIsDisabled(true)
    try {
      const params = updateUserInfo
      const { data: response } = await updateUserAPI(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleCloseUserModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickupdateTempAmount = async (isDisabled: boolean) => {
    setIsDisabled(true)
    try {
      const params = updateTempAmount
      if (isDisabled) {
        params.tempAmount = null
      }
      const { data: response } = await updateTempAmountAPI(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleCloseTempAmountModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickTokenLockUp = async () => {
    setIsDisabled(true)
    try {
      const params = lockup
      const { data: response } = await tokenLockUp(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleCloseLockUpModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickRecoveryToekn = async () => {
    console.log('recoveryToken => ', recoveryToken)
    if (
      recoveryToken.gasPrice === undefined ||
      recoveryToken.totalFee === undefined ||
      recoveryToken.gasLimit === undefined
    ) {
      toast.warning('예상 수수료 확인 버튼을 눌러주세요.', {
        theme: mode as Theme
      })
      return
    }

    setIsDisabled(true)
    try {
      const params = recoveryToken
      const { data: response } = await recoveryTokenTransfer(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleCloseLockUpModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickEstimateFee = async () => {
    setIsDisabled(true)
    try {
      const params = recoveryToken
      const { data: response } = await estimateFee(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        setRecoveryToken({
          ...recoveryToken,
          totalFee: response.data.ethUnitTotalGas,
          gasLimit: response.data.gasLimit,
          gasPrice: response.data.gasPrice
        })
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  const handleClickGetEthBalance = async (selectRow) => {
    setIsDisabled(true)
    try {
      const params = {
        userId: recoveryToken.userId
      }
      const { data: response } = await getEthBalance(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        setSelectRow({
          ...selectRow,
          ethBalance: response.data.balance
        })
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // init data
  const initData = async () => {
    // set select box data
    const isAdminData = [
      { label: intl.formatMessage({ id: 'User' }), value: 0 },
      { label: intl.formatMessage({ id: 'Admin' }), value: 1 }
    ]

    const isDeveloperData = [
      { label: intl.formatMessage({ id: 'User' }), value: 0 },
      { label: intl.formatMessage({ id: 'Developer' }), value: 1 }
    ]

    const data = [
      { label: intl.formatMessage({ id: 'Certified' }), value: 1 },
      { label: intl.formatMessage({ id: 'Uncertified' }), value: 0 }
    ]

    setSelectBoxAdmin(isAdminData)
    setSelectBoxDeveloper(isDeveloperData)
    setSelectBoxEmail(data)
    setSelectBoxSMS(data)
    setSelectBoxOTP(data)

    // set table
    try {
      const createdAt = search.createdAt
        ? moment(search.createdAt).format(DATE.ONLY_DATE)
        : ''

      const params = {
        page: pagination.activePage,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await walletListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.USER.WALLET_MANAGEMENT' })}
      </PageTitle>

      <WalletManagementPage
        intl={intl}
        isDisabled={isDisabled}
        selectRow={selectRow}
        search={search}
        lockup={lockup}
        recoveryToken={recoveryToken}
        pagination={pagination}
        selectBoxAdmin={selectBoxAdmin}
        selectBoxDeveloper={selectBoxDeveloper}
        selectBoxEmail={selectBoxEmail}
        selectBoxSMS={selectBoxSMS}
        selectBoxOTP={selectBoxOTP}
        showModal={showModal}
        showUserModal={showUserModal}
        showTempAmountModal={showTempAmountModal}
        updateUserInfo={updateUserInfo}
        updateTempAmount={updateTempAmount}
        handleChangePage={handleChangePage}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        handleOpenUserModal={handleOpenUserModal}
        handleCloseUserModal={handleCloseUserModal}
        handleOpenTempAmountModal={handleOpenTempAmountModal}
        handleCloseTempAmountModal={handleCloseTempAmountModal}
        handleChangeUserInfo={handleChangeUserInfo}
        handleChangeTempAmount={handleChangeTempAmount}
        handleClickUpdateUser={handleClickUpdateUser}
        handleClickupdateTempAmount={handleClickupdateTempAmount}
        showLockUpModal={showLockUpModal}
        handleOpenLockUpModal={handleOpenLockUpModal}
        handleCloseLockUpModal={handleCloseLockUpModal}
        handleChangeLockUp={handleChangeLockUp}
        handleClickTokenLockUp={handleClickTokenLockUp}
        handleChangeRecoveryToken={handleChangeRecoveryToken}
        showRecoveryTokenModal={showRecoveryTokenModal}
        handleOpenRecoveryTokenModal={handleOpenRecoveryTokenModal}
        handleCloseRecoveryTokenModal={handleCloseRecoveryTokenModal}
        handleClickEstimateFee={handleClickEstimateFee}
        handleClickRecoveryToekn={handleClickRecoveryToekn}
        handleClickGetEthBalance={handleClickGetEthBalance}
      />
    </>
  )
}

export { WalletManagementWrapper }
