/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'
import { signUp } from '../../../../api/user'
import { Modal } from 'react-bootstrap'
import { privacy, service } from '../../../../api/terms'

export function SignUp() {
  // hooks
  const navigate = useNavigate()
  const { saveAuth } = useAuth()
  const intl = useIntl()
  const lang = useLang()

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Wrong email format' }))
      .min(3, intl.formatMessage({ id: 'Minimum 3 symbols' }))
      .max(100, intl.formatMessage({ id: 'Maximum 100 symbols' }))
      .required(intl.formatMessage({ id: 'Email is required' })),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: 'Minimum 8 symbols' }))
      .max(100, intl.formatMessage({ id: 'Maximum 100 symbols' }))
      .required(intl.formatMessage({ id: 'Password is required' })),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({ id: 'Password confirmation is required' }))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({
            id: "Password and Confirm Password didn't match"
          })
        )
      }),
    username: Yup.string()
      .min(1, intl.formatMessage({ id: 'Minimum 1 symbols' }))
      .max(50, intl.formatMessage({ id: 'Maximum 50 symbols' }))
      .required(intl.formatMessage({ id: 'Username is required' }))
  })

  // state
  const [loading, setLoading] = useState(false)

  // state
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [showPolicyModal, setShowPolicyModal] = useState(false)

  // state
  const [terms, setTerms] = useState('')
  const [policy, setPolicy] = useState('')
  const [checkTerms, setCheckTerms] = useState(false)
  const [checkPolicy, setCheckPolicy] = useState(false)

  // handler
  const handleOpenTermsModal = () => {
    setShowTermsModal(true)
  }

  // handler
  const handleCloseTermsModal = () => {
    setShowTermsModal(false)
  }

  const handleOpenPolicyModal = () => {
    setShowPolicyModal(true)
  }

  // handler
  const handleClosePolicyModal = () => {
    setShowPolicyModal(false)
  }

  // init
  const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
    username: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!(checkTerms && checkPolicy)) {
        toast.warning(
          intl.formatMessage({
            id: 'You need to agree to the terms and conditions of use and personal information processing policy.'
          }),
          {
            theme: 'dark'
          }
        )
        return false
      }
      setLoading(true)
      try {
        const params = {
          account: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          username: values.username,
          lang
        }
        const { data: response } = await signUp(params)
        alert(intl.formatMessage({ id: response.message }))
        navigate('/')
      } catch (error) {
        toast.warning(intl.formatMessage({ id: error.response.data.message }), {
          theme: 'dark'
        })
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  const initData = async () => {
    const { data: terms } = await service()
    setTerms(terms?.data)

    const { data: policy } = await privacy()
    setPolicy(policy?.data)
  }

  // lifecycle
  useEffect(() => {
    PasswordMeterComponent.bootstrap()

    // init data
    initData()
  }, [])

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: 'Sign Up' })}
        </h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: 'Email' })}
        </label>
        <input
          placeholder="sample@sample.com"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
        <div className="text-muted">
          {intl.formatMessage({
            id: 'We will send you an email to activate your account when you complete your membership registration.'
          })}
        </div>
        <div className="text-muted">
          {intl.formatMessage({
            id: 'Please click on the link in the body of the mail.'
          })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: 'Password' })}
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.password && formik.errors.password
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          {intl.formatMessage({
            id: 'Use 8 or more characters with a mix of letters, numbers & symbols.'
          })}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: 'Confirm Password' })}
        </label>
        <input
          type="password"
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.confirmPassword && formik.errors.confirmPassword
            },
            {
              'is-valid':
                formik.touched.confirmPassword && !formik.errors.confirmPassword
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Username */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: 'Username' })}
        </label>
        <input
          placeholder="username"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            {
              'is-valid': formik.touched.username && !formik.errors.username
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className="mt-5">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checkTerms}
            id="terms"
            onChange={() => {
              checkTerms ? setCheckTerms(false) : setCheckTerms(true)
            }}
          />
          <label className="form-check-label">
            <div className="c-cp" onClick={handleOpenTermsModal}>
              {intl.formatMessage({
                id: 'I agree to the instructions on the terms and conditions. (Must-read)'
              })}
            </div>
          </label>
        </div>
      </div>

      <div className="mt-3 mb-10">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checkPolicy}
            id="policy"
            onChange={() => {
              checkPolicy ? setCheckPolicy(false) : setCheckPolicy(true)
            }}
          />
          <label className="form-check-label">
            <div className="c-cp" onClick={handleOpenPolicyModal}>
              {intl.formatMessage({
                id: 'I agree with the guidance on the personal information processing policy. (Must-read)'
              })}
            </div>
          </label>
        </div>
      </div>

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: 'Sign Up' })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'Please wait...' })}{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}

      <div>
        <button
          className="btn btn-light-dark w-100"
          onClick={() => {
            navigate('/auth/signIn')
          }}
        >
          {intl.formatMessage({ id: 'Move Sign In Page' })}
        </button>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal show={showTermsModal} onHide={handleCloseTermsModal}>
        <div className="modal-close">
          <i
            className="modal-close-icon bi bi-x-lg"
            onClick={handleCloseTermsModal}
          ></i>
        </div>
        <h1 className="c-tac mt-5 mb-5">
          {intl.formatMessage({ id: 'Terms and Conditions' })}
        </h1>
        <div
          style={{ color: '#ffffff', marginBottom: '0px' }}
          dangerouslySetInnerHTML={{ __html: terms }}
        />
        <div className="modal-button">
          <button
            className="btn btn-light-success ml-3"
            onClick={() => {
              setCheckTerms(true)
              handleCloseTermsModal()
            }}
          >
            <i className="bi bi-check fs-4 me-2"></i>
            {intl.formatMessage({ id: 'I agree' })}
          </button>
        </div>
      </Modal>

      <Modal show={showPolicyModal} onHide={handleClosePolicyModal}>
        <div className="modal-close">
          <i
            className="modal-close-icon bi bi-x-lg"
            onClick={handleClosePolicyModal}
          ></i>
        </div>
        <h1 className="c-tac mt-5 mb-5">
          {intl.formatMessage({ id: 'Personal information processing policy' })}
        </h1>
        <div
          style={{ color: '#ffffff', marginBottom: '0px' }}
          dangerouslySetInnerHTML={{ __html: policy }}
        />
        <div className="modal-button">
          <button
            className="btn btn-light-success ml-3"
            onClick={() => {
              setCheckPolicy(true)
              handleClosePolicyModal()
            }}
          >
            <i className="bi bi-check fs-4 me-2"></i>
            {intl.formatMessage({ id: 'I agree' })}
          </button>
        </div>
      </Modal>
    </form>
  )
}
