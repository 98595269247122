import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DATE from '../../constants/date'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { createNotice, noticePagination } from '../../../api/admin'
import { Modal } from 'react-bootstrap'

const NoticeAdminPage = ({
  intl,
  isDisabled,
  inputData,
  showModal,
  pagination,
  handleChangePage,
  handleClickAdd,
  handleClickUpdate,
  handleOpenModal,
  handleCloseModal,
  handleChangeInputData
}) => (
  <>
    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button className="btn btn-light-success" onClick={handleOpenModal}>
            <i className="bi bi-arrow-left-circle mr-1"></i>
            {intl.formatMessage({ id: 'Add' })}
          </button>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>
            <div className="c-tac">
              {intl.formatMessage({
                id: 'Data does not exist.'
              })}
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Title' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Hide' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of creation' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of update' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="c-cp"
                        onClick={handleClickUpdate}
                      >
                        <th>{item.id}</th>
                        <th>{item.title}</th>
                        <th>
                          {item.isHide === 0 ? (
                            <>
                              <span className="badge badge-success">
                                {intl.formatMessage({
                                  id: 'VALUE.SHOW'
                                })}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">
                                {intl.formatMessage({
                                  id: 'VALUE.HIDE'
                                })}
                              </span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                        <th>
                          {item.updatedAt
                            ? moment(item.updatedAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">{intl.formatMessage({ id: 'Add' })}</h1>
      <div>
        <div className="form-group mt-5">
          <label htmlFor="title">{intl.formatMessage({ id: 'Title' })}</label>
          <input
            type="text"
            className="form-control mt-3"
            id="title"
            onChange={(e) => handleChangeInputData('title', e)}
            value={inputData.title}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="content">
            {intl.formatMessage({ id: 'Content' })}
          </label>
          <textarea
            className="form-control mt-3"
            id="content"
            value={inputData.content}
            onChange={(e) => handleChangeInputData('content', e)}
          />
        </div>
      </div>
      <div className="modal-button">
        <button
          className="btn btn-light-success"
          onClick={handleClickAdd}
          disabled={isDisabled}
        >
          <i className="bi bi-plus fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Add' })}
        </button>
      </div>
    </Modal>
  </>
)

const NoticeAdminWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // state
  const [isDisabled, setIsDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [inputData, setInputData] = useState({
    title: '',
    content: ''
  })

  // handler
  const handleOpenModal = () => {
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // handler
  const handleChangeInputData = (type: string, data: any) => {
    if (type === 'title') {
      setInputData({ ...inputData, title: data.target.value })
    } else if (type === 'content') {
      setInputData({ ...inputData, content: data.target.value })
    }
  }

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber
      }
      const { data: response } = await noticePagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.noticeTotalCount,
          data: response.data.noticeList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler
  const handleClickAdd = async () => {
    try {
      setIsDisabled(true)
      const params = {
        title: inputData.title,
        content: inputData.content
      }
      const { data: response } = await createNotice(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        setIsDisabled(false)
        handleCloseModal()
        initData()
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickUpdate = () => {
    alert('수정/삭제 준비중입니다.')
  }

  // init data
  const initData = async () => {
    // set table
    try {
      const params = {
        page: pagination.activePage
      }
      const { data: response } = await noticePagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.noticeTotalCount,
          data: response.data.noticeList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Notice' })}
      </PageTitle>

      <NoticeAdminPage
        intl={intl}
        isDisabled={isDisabled}
        inputData={inputData}
        showModal={showModal}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleClickAdd={handleClickAdd}
        handleClickUpdate={handleClickUpdate}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        handleChangeInputData={handleChangeInputData}
      />
    </>
  )
}

export { NoticeAdminWrapper }
