import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/admin/'

// ANCHOR user list pagination API
export const userListPagination = (params: object) => {
  const url = `${rootURL}userListPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR create notice
export const createNotice = (params: object) => {
  const url = `${rootURL}createNotice`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR create FAQ
export const createFAQ = (params: object) => {
  const url = `${rootURL}createFAQ`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR update user
export const updateUser = (params: object) => {
  const url = `${rootURL}updateUser`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR update password
export const updatePassword = (params: object) => {
  const url = `${rootURL}updatePassword`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR user sign in  pagination API
export const userSignInPagination = (params: object) => {
  const url = `${rootURL}userSignInPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR coin external transfer pagination API
export const coinExternalTransferPagination = (params: object) => {
  const url = `${rootURL}coinExternalTransferPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR coin external transfer pagination API
export const coinInternalTransferPagination = (params: object) => {
  const url = `${rootURL}coinExternalTransferPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR kew deposit and withdrawal pagination API
export const krwDepositAndWithdrawalPagination = (params: object) => {
  const url = `${rootURL}krwDepositAndWithdrawalPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR lock up pagination
export const lockUpPagination = (params: object) => {
  const url = `${rootURL}lockUpPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}
// ANCHOR faq  pagination API
export const faqPagination = (params: object) => {
  const url = `${rootURL}faqPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}
// ANCHOR notice pagination API
export const noticePagination = (params: object) => {
  const url = `${rootURL}noticePagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR notice detail API
export const getFaqDetail = (params: object) => {
  const url = `${rootURL}getFaqDetail`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR FAQ detail API
export const getNoticeDetail = (params: object) => {
  const url = `${rootURL}getNoticeDetail`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR create FAQ API
export const createFaqDetail = (params: object) => {
  const url = `${rootURL}createFaqDetail`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR update FAQ API
export const updateFaqDetail = (params: object) => {
  const url = `${rootURL}updateFaqDetail`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR get bank account API
export const getBankAccount = () => {
  const url = `${rootURL}bankAccount`
  const response = axios.get<CommonResponse>(url)
  return response
}

// ANCHOR update bank account API
export const updateBankAccount = (params: object) => {
  const url = `${rootURL}bankAccount`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR wallet list pagination API
export const walletListPagination = (params: object) => {
  const url = `${rootURL}walletListPagination`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

// ANCHOR update tempAmount API
export const updateTempAmount = (params: object) => {
  const url = `${rootURL}updateTempAmount`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR tokenLockUp API
export const tokenLockUp = (params: object) => {
  const url = `${rootURL}tokenLockUp`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR tokenLockUp API
export const estimateFee = (params: object) => {
  const url = `${rootURL}estimateFee`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR recoveryTokenTransfer API
export const recoveryTokenTransfer = (params: object) => {
  const url = `${rootURL}recoveryToken`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

// ANCHOR getEthBalance
export const getEthBalance = (params: object) => {
  const url = `${rootURL}ethBalance`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}
