import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import QRCode from 'react-qr-code'
import BigNumber from 'bignumber.js'
import { getAddress, getBalance } from '../../../api/wallet'
import moment from 'moment'
import {
  getHistory as getHistoryERC,
  getFee as getFeeERC,
  outerSend as outerSendERC
} from '../../../api/erc'
import {
  getHistory as getHistoryETH,
  getFee as getFeeETH,
  outerSend as outerSendETH
} from '../../../api/eth'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { Modal } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FastField } from 'formik'

const DepositAndWithdrawalPage = ({
  intl,
  mode,
  tab,
  showModal,
  feeETH,
  feeERC,
  isDisabled,
  totalKRW,
  coinList,
  selectCoin,
  selectCoinSymbol,
  selectCoinAmount,
  walletAddress,
  withdrawalCoin,
  historyERC,
  historyETH,
  handleClickTab,
  handleClickReload,
  handleClickCoin,
  handleChangeWithdrawalCoin,
  handleSendWithdrawal,
  handleOpenModal,
  handleCloseModal
}) => (
  <>
    <div className="row">
      <div className="col-md-6 col-12 mt-3">
        <div className="card card-custom">
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                {intl.formatMessage({ id: 'Total Assets Held' })}
              </div>
              <div className="col-9 c-tar">{totalKRW} KRW</div>
            </div>

            <div className="mt-5">
              <div className="table-responsive">
                <table className="table table-rounded border gy-7 gs-7">
                  <thead>
                    <tr>
                      <th scope="col">
                        {intl.formatMessage({ id: 'Coin name' })}
                      </th>
                      <th scope="col">
                        {intl.formatMessage({ id: 'the quantity held' })} (
                        {intl.formatMessage({ id: 'Valuation amount' })})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coinList.map((coin, idx) => {
                      return (
                        <tr
                          key={idx}
                          className={`${coin.lockup === 0 ? 'c-cp' : ''} ${
                            selectCoin === coin.coinFullName &&
                            coin.lockup === 0
                              ? 'bg-light-primary'
                              : ''
                          }`}
                          onClick={() => {
                            if (coin.lockup === 0) {
                              handleClickCoin(coin.coinFullName, coin.coinName)
                            }
                          }}
                        >
                          <th>
                            <div className="row">
                              <div className="col">
                                <img
                                  className="daw-symbol"
                                  src={coin.symbol}
                                  alt={coin.coinName}
                                />
                              </div>
                              <div className="col">
                                <div>{coin.coinFullName}</div>
                                <div className="daw-gray">{coin.coinName}</div>
                              </div>
                            </div>
                          </th>
                          <td>
                            <div className="c-tar">
                              <div>
                                {coin.coinAmount}{' '}
                                <span className="daw-gray">
                                  {coin.coinName}
                                </span>
                              </div>
                              <div>
                                {coin.KRWAmount}{' '}
                                <span className="daw-gray">KRW</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="c-tar">
                <button
                  className="btn btn-primary"
                  onClick={handleClickReload}
                  disabled={isDisabled}
                >
                  <i className="bi bi-arrow-clockwise fs-4 me-2"></i>
                  {intl.formatMessage({ id: 'Refresh' })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectCoin === 'KRW' ? (
        <></>
      ) : (
        <>
          <div className="col-md-6 col-12 mt-3">
            <div className="card card-custom">
              <div className="card-body">
                <div className="c-fs20">
                  {selectCoin}{' '}
                  {intl.formatMessage({ id: 'Deposit and withdrawal' })}
                </div>

                <div className="row mt-5">
                  <div className="col">
                    {intl.formatMessage({ id: 'the quantity held' })}
                  </div>
                  <div className="col c-tar">
                    {selectCoinAmount} {selectCoinSymbol}
                  </div>
                </div>

                <div className="mt-5">
                  <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                    <li className="nav-item c-cp">
                      <div
                        className={`nav-link ${tab === 1 ? 'active' : ''}`}
                        onClick={() => handleClickTab(1)}
                      >
                        {intl.formatMessage({ id: 'Deposit' })}
                      </div>
                    </li>
                    <li className="nav-item c-cp">
                      <div
                        className={`nav-link ${tab === 2 ? 'active' : ''}`}
                        onClick={() => handleClickTab(2)}
                      >
                        {intl.formatMessage({ id: 'Withdrawal' })}
                      </div>
                    </li>
                    <li className="nav-item c-cp">
                      <div
                        className={`nav-link ${tab === 3 ? 'active' : ''}`}
                        onClick={() => handleClickTab(3)}
                      >
                        {intl.formatMessage({
                          id: 'Deposit and withdrawal details'
                        })}
                      </div>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {tab === 1 ? (
                      <>
                        <div className="tab-pane fade active show">
                          <div className="mt-5">
                            {intl.formatMessage({
                              id: 'You can deposit the ERC-20 token to the address below assigned to you.'
                            })}
                          </div>

                          <div>
                            <div className="form-group mt-5">
                              <label htmlFor="walletAddress">
                                {intl.formatMessage({
                                  id: 'My ERC-20 Deposit Address'
                                })}{' '}
                                ({intl.formatMessage({ id: 'Deposit only' })})
                              </label>
                              <div className="row">
                                <div className="col-9">
                                  <input
                                    type="text"
                                    className="form-control mt-3"
                                    id="walletAddress"
                                    readOnly
                                    value={walletAddress}
                                  />
                                </div>
                                <div className="col-3">
                                  <CopyToClipboard
                                    text={walletAddress}
                                    onCopy={() => {
                                      toast.success(
                                        intl.formatMessage({
                                          id: 'Your wallet address has been copied.'
                                        }),
                                        {
                                          theme: mode as Theme
                                        }
                                      )
                                    }}
                                  >
                                    <button className="btn btn-primary mt-3 c-w100p">
                                      {intl.formatMessage({ id: 'Copy' })}
                                    </button>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-5">
                            <div>{intl.formatMessage({ id: 'QR Code' })}</div>
                            <div className="mt-3 c-tac">
                              {walletAddress !== '' ? (
                                <>
                                  <QRCode value={walletAddress} />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="mt-5">
                            <div>
                              *{' '}
                              {intl.formatMessage({
                                id: 'Make sure to know before you deposit!'
                              })}
                            </div>
                            <div className="mt-2">
                              -{' '}
                              {intl.formatMessage({
                                id: 'The above address is for deposit only.'
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {tab === 2 ? (
                      <>
                        <div className="tab-pane fade active show">
                          <div className="tab-pane fade active show">
                            <div className="form-group mt-5">
                              <label htmlFor="withdrawalCoinWalletAddressEx">
                                {intl.formatMessage({
                                  id: 'Withdrawal address'
                                })}
                              </label>
                              <input
                                type="text"
                                className="form-control mt-3"
                                id="withdrawalCoinWalletAddressEx"
                                value={withdrawalCoin.walletAddress}
                                onChange={(e) =>
                                  handleChangeWithdrawalCoin('walletAddress', e)
                                }
                              />
                            </div>
                            <div className="form-group mt-5">
                              <label htmlFor="withdrawalCoinAmountEx">
                                {intl.formatMessage({
                                  id: 'Withdrawal quantity'
                                })}
                              </label>
                              <input
                                type="text"
                                className="form-control mt-3"
                                id="withdrawalCoinAmountEx"
                                value={withdrawalCoin.amount}
                                onChange={(e) =>
                                  handleChangeWithdrawalCoin('amount', e)
                                }
                              />
                            </div>
                            <div className="form-group mt-5">
                              <label htmlFor="withdrawalCoinFeeEx">
                                {intl.formatMessage({ id: 'Withdrawal fee' })}{' '}
                                (ETH)
                              </label>
                              <input
                                type="text"
                                className="form-control mt-3"
                                id="withdrawalCoinFeeEx"
                                value={
                                  selectCoin === 'Casting' ? feeERC : feeETH
                                }
                                disabled
                              />
                            </div>
                            <div className="form-group mt-5">
                              <label htmlFor="withdrawalCoinTotalAmountEx">
                                {intl.formatMessage({
                                  id: 'a total withdrawal'
                                })}
                              </label>
                              <input
                                type="text"
                                className="form-control mt-3"
                                id="withdrawalCoinTotalAmountEx"
                                value={withdrawalCoin.totalAmount}
                                disabled
                              />
                            </div>
                            <div className="form-group mt-5">
                              <label htmlFor="token">
                                {intl.formatMessage({
                                  id: 'OTP code'
                                })}
                              </label>
                              <input
                                type="password"
                                className="form-control mt-3"
                                id="token"
                                value={withdrawalCoin.token}
                                onChange={(e) =>
                                  handleChangeWithdrawalCoin('token', e)
                                }
                              />
                              <div className="c-yellow">
                                *{' '}
                                {intl.formatMessage({
                                  id: 'OTP can be registered in the account settings.'
                                })}
                              </div>
                            </div>

                            <div className="mt-5">
                              <div>
                                *{' '}
                                {intl.formatMessage({
                                  id: 'Precautions for withdrawal of cryptocurrency'
                                })}
                              </div>
                              <div className="mt-2">
                                -{' '}
                                {intl.formatMessage({
                                  id: 'Due to the nature of digital assets, it cannot be canceled once the withdrawal application has been completed.'
                                })}
                              </div>
                              <div className="mt-2">
                                -{' '}
                                {intl.formatMessage({
                                  id: "You can send money only with ERC20 wallet. There's nothing I can do to help you send the wrong money to another digital asset wallet."
                                })}
                              </div>
                            </div>

                            <div className="mt-5">
                              <button
                                className="btn btn-success c-w100p"
                                onClick={handleOpenModal}
                              >
                                <i className="bi bi-send fs-4 me-2"></i>
                                {intl.formatMessage({ id: 'Withdrawal' })}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {tab === 3 ? (
                      <>
                        <div className="tab-pane fade active show">
                          <div className="mb-5">
                            {selectCoin}{' '}
                            {intl.formatMessage({
                              id: 'Deposit and withdrawal details'
                            })}
                          </div>

                          {selectCoin === 'Casting' &&
                          historyERC.length === 0 ? (
                            <></>
                          ) : (
                            <></>
                          )}

                          {selectCoin === 'Ethereum' &&
                          historyERC.length === 0 ? (
                            <></>
                          ) : (
                            <></>
                          )}

                          {selectCoin === 'Casting' &&
                          historyERC.length !== 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table table-rounded table-striped border gy-7 gs-7">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Sender Wallet Address'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Receiver Wallet Address'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Amount'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Time'
                                        })}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {historyERC.map((item, idx) => {
                                      const date = new Date(
                                        Number(item.timeStamp) * 1000
                                      )
                                      return (
                                        <tr key={idx}>
                                          <th>{item.from}</th>
                                          <td>{item.to}</td>
                                          <td>{item.stringValue}</td>
                                          <td>
                                            {moment(date).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {selectCoin === 'Ethereum' &&
                          historyETH.length !== 0 ? (
                            <>
                              <div className="table-responsive">
                                <table className="table table-rounded table-striped border gy-7 gs-7">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Sender Wallet Address'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Receiver Wallet Address'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Amount'
                                        })}
                                      </th>
                                      <th scope="col">
                                        {intl.formatMessage({
                                          id: 'Time'
                                        })}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {historyETH.map((item, idx) => {
                                      const date = new Date(
                                        Number(item.timeStamp) * 1000
                                      )
                                      return (
                                        <tr key={idx}>
                                          <th>{item.from}</th>
                                          <td>{item.to}</td>
                                          <td>{item.stringValue}</td>
                                          <td>
                                            {moment(date).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="mt-5">
                            *{' '}
                            {intl.formatMessage({
                              id: 'You can check the deposit and withdrawal details.'
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Withdrawal' })}
      </h1>
      <div>
        <div className="form-group mt-5">
          <label htmlFor="withdrawalAddress">
            {intl.formatMessage({ id: 'Withdrawal address' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="withdrawalAddress"
            disabled
            value={withdrawalCoin.walletAddress}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="withdrawalAmount">
            {intl.formatMessage({ id: 'Withdrawal quantity' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="withdrawalAmount"
            disabled
            value={withdrawalCoin.amount}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="withdrawalFee">
            {intl.formatMessage({ id: 'Withdrawal fee' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="withdrawalFee"
            disabled
            value={selectCoin === 'Casting' ? feeERC : feeETH}
          />
        </div>
      </div>
      <div className="mt-5 c-fs15" style={{ color: 'red' }}>
        {intl.formatMessage({
          id: 'Please check the wallet address, withdrawal quantity, and withdrawal fee again.'
        })}
      </div>
      <div className="modal-button">
        <button
          className="btn btn-success c-w100p"
          onClick={handleSendWithdrawal}
          disabled={isDisabled}
        >
          <i className="bi bi-send fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Withdrawal' })}
        </button>
      </div>
    </Modal>
  </>
)

const DepositAndWithdrawalWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const { mode } = useThemeMode()

  // 초기 코인 선택
  let initSelectCoin = 'Casting'
  let initSelectCoinSymbol = 'CST'
  const [selectCoin, setSelectCoin] = useState(initSelectCoin)
  const [selectCoinSymbol, setSelectCoinSymbol] = useState(initSelectCoinSymbol)
  const [selectCoinAmount, setSelectCoinAmount] = useState('')

  // state
  const [showModal, setShowModal] = useState(false)

  // 입금하기(1), 출금하기(2). 입출금내역(3)
  const [tab, setTab] = useState(1)

  // 지갑주소
  const [walletAddress, setWalletAddress] = useState('')

  // 총 보유자산
  const [totalKRW, setTotalKRW] = useState(0)

  // 코인 리스트
  const [coinList, setCoinList] = useState([])

  // 수수료
  const [feeETH, setFeeETH] = useState('')
  const [feeERC, setFeeERC] = useState('')

  // 비활성화
  const [isDisabled, setIsDisabled] = useState(false)

  // 출금하기 Input
  const initWithdrawalCoin = {
    walletAddress: '',
    amount: '',
    totalAmount: '',
    token: ''
  }
  const [withdrawalCoin, setWithdrawalCoin] = useState(initWithdrawalCoin)

  // 토큰 입출금내역
  const [historyERC, setHistoryERC] = useState([])

  // 이더리움 입출금내역
  const [historyETH, setHistoryETH] = useState([])

  // handler
  const handleOpenModal = () => {
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // handler - 탭 변경
  const handleClickTab = (tabNo: number) => {
    setTab(tabNo)
    clearForm()
  }

  // handler - 새로고침
  const handleClickReload = async () => {
    setIsDisabled(true)
    await initData()
    setIsDisabled(false)
  }

  // handler - 출금신청
  const handleSendWithdrawal = async () => {
    setIsDisabled(true)
    if (selectCoin === 'Casting') {
      try {
        const paramsETH = {
          toAddress: withdrawalCoin.walletAddress,
          amount: Number(withdrawalCoin.totalAmount),
          token: withdrawalCoin.token
        }
        const { data: responseETH } = await outerSendERC(paramsETH)
        if (responseETH.statusCode === 200) {
          toast.info(intl.formatMessage({ id: responseETH.message }), {
            theme: mode as Theme
          })
          setIsDisabled(false)
        }
      } catch (error) {
        toast.warning(intl.formatMessage({ id: error.response.data.message }), {
          theme: mode as Theme
        })
        setIsDisabled(false)
      }
    } else {
      try {
        const paramsERC = {
          toAddress: withdrawalCoin.walletAddress,
          amount: Number(withdrawalCoin.totalAmount),
          token: withdrawalCoin.token
        }
        const { data: responseETH } = await outerSendETH(paramsERC)
        if (responseETH.statusCode === 200) {
          toast.info(intl.formatMessage({ id: responseETH.message }), {
            theme: mode as Theme
          })
          setIsDisabled(false)
        }
      } catch (error) {
        toast.warning(intl.formatMessage({ id: error.response.data.message }), {
          theme: mode as Theme
        })
        setIsDisabled(false)
      }
    }
  }

  // 출금하기 Input 초기화
  const clearForm = () => {
    setWithdrawalCoin({ ...initWithdrawalCoin })
  }

  // handler - 코인 선택
  const handleClickCoin = (coinFullName: string, coinName: string) => {
    setSelectCoin(coinFullName)
    setSelectCoinSymbol(coinName)
    setTab(1)

    // 보유 수량 갱신
    for (let i = 0; i < coinList.length; i++) {
      const coin = coinList[i]
      if (coin.coinName === coinName) {
        setSelectCoinAmount(coin.coinStringAmount)
      }
    }
  }

  // handler - 출금하기 Input 변경
  const handleChangeWithdrawalCoin = (key, val) => {
    if (key === 'walletAddress') {
      setWithdrawalCoin({ ...withdrawalCoin, walletAddress: val.target.value })
    } else if (key === 'amount') {
      const amount = val.target.value
      let totalAmount = amount
      if (selectCoin === 'Ethereum') {
        const totalAmountBN = new BigNumber(totalAmount)
        totalAmount = totalAmountBN.plus(feeETH).toString()
      }
      setWithdrawalCoin({
        ...withdrawalCoin,
        amount,
        totalAmount
      })
    } else if (key === 'token') {
      setWithdrawalCoin({ ...withdrawalCoin, token: val.target.value })
    }
  }

  // 초기화
  const initData = async () => {
    const { data: wallet } = await getAddress()
    setWalletAddress(wallet.data)

    const { data: balance } = await getBalance()
    setCoinList(balance.data.balances)
    setTotalKRW(balance.data.totalAssets)

    for (let i = 0; i < balance.data.balances.length; i++) {
      const coin = balance.data.balances[i]
      if (coin.coinName === selectCoinSymbol) {
        setSelectCoinAmount(coin.coinStringAmount)
      }
    }

    const params = {
      page: 1
    }
    const { data: erc } = await getHistoryERC(params)
    setHistoryERC(erc.data)

    const { data: eth } = await getHistoryETH(params)
    setHistoryETH(eth.data)

    const { data: ercFee } = await getFeeERC()
    setFeeERC(ercFee.data)

    const { data: ethFee } = await getFeeETH()
    setFeeETH(ethFee.data)
  }

  // lifecycle
  useEffect(() => {
    // init data
    initData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Deposit and withdrawal' })}
      </PageTitle>

      <DepositAndWithdrawalPage
        intl={intl}
        mode={mode}
        tab={tab}
        showModal={showModal}
        feeETH={feeETH}
        feeERC={feeERC}
        isDisabled={isDisabled}
        totalKRW={totalKRW}
        coinList={coinList}
        selectCoin={selectCoin}
        selectCoinSymbol={selectCoinSymbol}
        selectCoinAmount={selectCoinAmount}
        walletAddress={walletAddress}
        withdrawalCoin={withdrawalCoin}
        historyERC={historyERC}
        historyETH={historyETH}
        handleClickTab={handleClickTab}
        handleClickReload={handleClickReload}
        handleClickCoin={handleClickCoin}
        handleChangeWithdrawalCoin={handleChangeWithdrawalCoin}
        handleSendWithdrawal={handleSendWithdrawal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export { DepositAndWithdrawalWrapper }
