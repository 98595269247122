import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getSupport } from '../../../api/customer'
import { PageTitle } from '../../../_metronic/layout/core'

const SupportPage = ({ intl, support }) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div>
          {intl.formatMessage({
            id: 'Do you need any assistance? If you contact us at the number below, we will help you through consultation.'
          })}
        </div>
      </div>
    </div>

    <div className="mt-5">{intl.formatMessage({ id: 'Enquiry Call' })}</div>
    <div className="card card-custom mt-3">
      <div className="card-body">
        <div className="c-fs15">{support.enquiryCall}</div>
      </div>
    </div>

    <div className="mt-5">{intl.formatMessage({ id: 'Enquiry KaKao' })}</div>
    <div className="card card-custom mt-3">
      <div className="card-body">
        <a href="https://open.kakao.com/o/svPa4vUe">
          https://open.kakao.com/o/svPa4vUe
        </a>
      </div>
    </div>

    <div className="mt-5">{intl.formatMessage({ id: 'Operating hours' })}</div>
    <div className="card card-custom mt-3">
      <div className="card-body">
        <div className="c-fs15">
          <div
            dangerouslySetInnerHTML={{ __html: support.operatingHours }}
          ></div>
        </div>
      </div>
    </div>
  </>
)

const SupportWrapper: FC = () => {
  // hooks
  const intl = useIntl()

  // state
  const [support, setSupport] = useState({
    enquiryCall: '',
    enquiryMail: '',
    operatingHours: ''
  })

  // init data
  const initData = async () => {
    const { data: response } = await getSupport()
    setSupport(response.data)
  }

  // lifecycle
  useEffect(() => {
    // init data
    initData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Get Support' })}
      </PageTitle>

      <SupportPage intl={intl} support={support} />
    </>
  )
}

export { SupportWrapper }
